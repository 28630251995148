import React from "react";
import Layout from "../components/_App/layout";
import Seo from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner'; 
import ContactInfo from '../components/Contact/ContactInfo';
import GoogleMap from '../components/Contact/GoogleMap';

const Contacts = ({ pageContext: { lang, data }, location: { pathname } }) => (
    <Layout>
        <Seo
            title={data.seoTitle}
            lang={lang}
        />

        <Navbar
            currentPath={pathname}
            lang={lang}
        />

        <PageBanner pageTitle={data.bannerTitle} />

        <ContactInfo data={data.info} />

        <GoogleMap lang={lang} />

        <Footer lang={lang} /> 
    </Layout>
)

export default Contacts;