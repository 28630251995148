import React from 'react';
import * as Icon from 'react-feather';

const ContactInfo = ({ data: { company, address, workingHours } }) => {
    return (
        <div className="contact-info-area ptb-80">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <Icon.Mail />
                            </div>
                            <h3>{company.title}</h3>

                            {
                                company.items.map((item) => (
                                    <p key={item.name}>
                                        {item.link
                                            ? <>{item.name}: <a href={item.link}>
                                                {item.text}</a>
                                            </>
                                            : <>{item.name}: {item.text}</>
                                        }
                                    </p>
                                ))
                            }
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <Icon.MapPin />
                            </div>

                            <h3>{address.title}</h3>
                            <p>
                                {address.text1}
                                <br />
                                 {address.text2}
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <Icon.Clock />
                            </div>
                            <h3>{workingHours.title}</h3>
                            
                            <p>{workingHours.text1}</p>
                            <p>{workingHours.text2}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo;  